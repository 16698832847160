import BaseService from "@core/services/baseService";

export default class ClientOwnerService extends BaseService {
    createClientOwner(...args){
        return this.axiosIns.post(this.formConfig.clientOwnerEndpoint, ...args)
    }

    updateClientOwner(...args){
        return this.axiosIns.post(this.formConfig.clientOwnerEndpoint+"/edit/"+args[0].id, ...args)
    }

    getClientOwner(...args){
        return this.axiosIns.get(this.formConfig.clientOwnerEndpoint+"/"+args[0].id, ...args)
    }

    deleteClientOwner(...args){
        return this.axiosIns.delete(this.formConfig.clientOwnerEndpoint+"/"+args[0].id)
    }

    getByClient(...args){
        return this.axiosIns.get(this.formConfig.clientOwnerEndpoint, {params: {'client_id': args[0].client_id}})
    }
}